export enum ShipmentTableColumnsType {
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
  SHIPMENT_NUMBER = 'shipmentNumber',
  DEPARTURE_LOCATION = 'departureLocation',
  FINAL_DESTINATION = 'finalDestination',
  PROCESS_STATUS = 'processStatus',
  REFERENCES = 'references',
  LOAD_CARRIES = 'loadCarriers',
  PORT_OF_LOADING = 'portOfLoading',
  MODE_OF_TRANSPORT ='modeOfTransport',
  PORT_OF_DETINSTINATION = 'portOfDestination',
}
