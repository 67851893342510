import { createAction, props } from '@ngrx/store';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import {
  Shipment,
  ShipmentActionDetail,
  ShipmentAttach,
  ShipmentQuery,
  ShipmentQueryFilters,
  ShipmentQueryResponse,
  ShipmentReject,
  ShipmentResend,
} from '@app/shipment/models';
import { OrderLineSearchView } from '@app/order/models';

export enum ShipmentActionType {
  QUERY = '[Shipment] Query',
  QUERY_SUCCESS = '[Shipment] Query Success',
  QUERY_FAILURE = '[Shipment] Query Failure',
  UPDATE_FILTERS = '[Shipment] Update Filters',
  INIT_COLUMNS = '[Shipment] Init Columns',
  INITIATED_COLUMNS = '[Shipment] Initiated Columns',
  UPDATE_COLUMNS = '[Shipment] Update Columns',
  ADD_MANY = '[Shipment] Add Many',
  REFRESH = '[Shipment] Refresh',

  SET_SHOW_DOCUMENT = '[Shipment Detail] Set show line data',

  LISTEN = '[Shipment] Listen',
  FETCH = '[Shipment] Fetch',
  FETCH_FAILURE = '[Shipment] Fetch Failure',
  UPSERT = '[Shipment] Upsert',
  WEBSOCKET_UPSERT = '[Shipment] Websocket Upsert',
  WEBSOCKET_CONNECTED = '[Shipment] Websocket Connected',
  START_CREATE_SHIPMENT = '[Shipment] Start Create',

  RESEND = '[Shipment Detail] Resend',
  RESEND_SUCCESS = '[Shipment Detail] Resend Success',
  RESEND_FAILURE = '[Shipment Detail] Resend Failure',

  APPROVE = '[Shipment Detail] Approve',
  APPROVE_SUCCESS = '[Shipment Detail] Approve Success',
  APPROVE_FAILURE = '[Shipment Detail] Approve Failure',

  START_REJECT = '[Shipment Detail] Start Reject',
  REJECT = '[Shipment Detail] Reject',
  REJECT_SUCCESS = '[Shipment Detail] Reject Success',
  REJECT_FAILURE = '[Shipment Detail] Reject Failure',

  PRE_REISSUE = '[Shipment Detail] Pre Reissue',
  START_REISSUE = '[Shipment Detail] Start Reissue',

  ATTACH = '[Shipment Detail] Attach document',
  ATTACH_FAILURE = '[Shipment Detail] Attach document Failure',
}

export const shipmentQuery = createAction(ShipmentActionType.QUERY, props<{ query: ShipmentQuery }>());
export const shipmentQuerySuccess = createAction(
  ShipmentActionType.QUERY_SUCCESS,
  props<{ query: ShipmentQuery; response: ShipmentQueryResponse }>(),
);
export const shipmentUpdateFilters = createAction(
  ShipmentActionType.UPDATE_FILTERS,
  props<{ filters: ShipmentQueryFilters }>(),
);
export const shipmentQueryFailure = createAction(ShipmentActionType.QUERY_FAILURE, props<{ error: string }>());
export const shipmentUpdateColumns = createAction(
  ShipmentActionType.UPDATE_COLUMNS,
  props<{ columns: OrderedColumn[] }>(),
);
export const shipmentInitColumns = createAction(ShipmentActionType.INIT_COLUMNS);
export const shipmentInitiatedColumns = createAction(
  ShipmentActionType.INITIATED_COLUMNS,
  props<{
    columns: OrderedColumn[];
  }>(),
);
export const shipmentAddMany = createAction(ShipmentActionType.ADD_MANY, props<{ entities: Shipment[] }>());
export const shipmentRefresh = createAction(ShipmentActionType.REFRESH);
export const shipmentStartCreate = createAction(ShipmentActionType.START_CREATE_SHIPMENT);

export const shipmentSetShowing = createAction(ShipmentActionType.SET_SHOW_DOCUMENT, props<{ value: boolean }>());

export const shipmentFetch = createAction(ShipmentActionType.FETCH, props<{ id: string; silent?: boolean }>());
export const shipmentFetchFailure = createAction(
  ShipmentActionType.FETCH_FAILURE,
  props<{ id: string; error: string; silent: boolean }>(),
);

export const shipmentListen = createAction(ShipmentActionType.LISTEN, props<{ companyId: string }>());
export const shipmentUpsert = createAction(ShipmentActionType.UPSERT, props<{ entity: Shipment }>());
export const shipmentWebSocketUpsert = createAction(ShipmentActionType.WEBSOCKET_UPSERT, props<{ entity: Shipment }>());
export const shipmentWebSocketConnected = createAction(ShipmentActionType.WEBSOCKET_CONNECTED);

export const shipmentDetailApprove = createAction(ShipmentActionType.APPROVE, props<ShipmentActionDetail>());
export const shipmentDetailApproveSuccess = createAction(ShipmentActionType.APPROVE_SUCCESS);
export const shipmentDetailApproveFailure = createAction(
  ShipmentActionType.APPROVE_FAILURE,
  props<{ error: any; status: number }>(),
);

export const shipmentDetailStartReject = createAction(ShipmentActionType.START_REJECT, props<ShipmentActionDetail>());
export const shipmentDetailReject = createAction(ShipmentActionType.REJECT, props<ShipmentReject>());
export const shipmentDetailRejectSuccess = createAction(ShipmentActionType.REJECT_SUCCESS);
export const shipmentDetailRejectFailure = createAction(
  ShipmentActionType.REJECT_FAILURE,
  props<{ error: any; status: number }>(),
);

export const shipmentDetailResend = createAction(ShipmentActionType.RESEND, props<ShipmentResend>());
export const shipmentDetailResendSuccess = createAction(ShipmentActionType.RESEND_SUCCESS);
export const shipmentDetailResendFailure = createAction(
  ShipmentActionType.RESEND_FAILURE,
  props<{ error: any; status: number }>(),
);
export const shipmentDetailAttach = createAction(ShipmentActionType.ATTACH, props<ShipmentAttach>());
export const shipmentDetailAttachFailure = createAction(
  ShipmentActionType.ATTACH_FAILURE,
  props<{ error: any; status: number }>(),
);

export const shipmentDetailPreReissue = createAction(ShipmentActionType.PRE_REISSUE, props<{ shipment: Shipment }>());
export const shipmentDetailReissue = createAction(
  ShipmentActionType.START_REISSUE,
  props<{ shipment: Shipment; lines: { [key: string]: OrderLineSearchView } }>(),
);
