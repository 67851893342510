import { createReducer, on } from '@ngrx/store';
import {
  CacheActionType,
  cachingActivityValue,
  cachingExpandValue,
  cachingOrderListLimit,
  cachingTaskLimit,
  getCachedActivityValue,
  getCachedConverterColumns,
  getCachedExpandValue,
  getCachedLinePanels,
  getCachedOrderListColumns,
  getCachedOrderListLimit,
  getCachedOrderPanels,
  getCachedOrdersFilters,
  getCachedOrdersSort,
  getCachedShipmentColumns,
  getCachedShipmentQuery,
  getCachedTaskFilters,
  getCachedTaskLimit,
  getCachedViewMode,
  updateLinePanels,
  updateOrderPanels,
  validatedCachedDestinations,
} from '@app/cache/state/cache.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { EntityTypeOptionValue } from '@app/activity/models/activity-entity-type-option.model';
import { OrderQueryFilters, OrderQuerySort, OrderQueryViewMode } from '@app/order/models';
import {
  OrderListActionType,
  orderListReset,
  orderListUpdateFilters,
  orderListUpdateLineColumns,
  orderListUpdateOrderColumns,
  orderListUpdateSort,
  orderListUpdateViewMode,
} from '@app/order/routes/order-list/state/order-list.actions';
import { TaskQueryFilters } from '@app/task/models';
import { taskListResetFilters, taskListUpdateFilters } from '@app/task/routes/task-list/state/task-list.actions';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { UserActionType, userFetchFailure } from '@app/user/core/state/user.actions';
import { CompanyActionType, companyFetchFailure } from '@app/company/core/state/company.actions';
import { ShipmentQueryFilters } from '@app/shipment/models';
import { shipmentUpdateColumns, shipmentUpdateFilters } from '@app/shipment/core/state/shipment.actions';
import { ConvertedColumn } from '@app/converter/models';
import { LinePanelType } from '@app/order/routes/order-line-detail/containers/view/line-panels/line-panels.component';
import { OrderPanelType } from '@app/order/routes/order-detail/containers/view/order-panels/order-panels.component';

export enum CachedActivityType {
  OrderPage = 'order',
  OrderLinePage = 'orderLine',
  DashboardPage = 'dashboard',
  UserSettingsPage = 'settings',
  UserNetworkPage = 'network',
  ShipmentPage = 'shipment',
}

export enum CachedExpandType {
  OrderProcess = 'processStatus',
  OrderLinePrediction = 'prediction',
  OrderType = 'orderType',
  OrderLogistics = 'logisticsStatus',
  OrderCustomLabels = 'customLabels',
  OrderContactIds = 'contactIds',
  OrderDestinations = 'destinations',
  OrderSearch = 'search',

  ConnectionCompanyIds = 'companyIds', // one for task / order

  TaskType = 'type',
  TaskContact = 'contact',
  ViewTasks = 'assignedTo',
  TaskContactIds = 'contactIds', // same as for order
  TaskModule = 'module',
  TaskCategory = 'category',
}

interface CachedOrderList {
  viewMode: OrderQueryViewMode | null;
  sort?: OrderQuerySort | null;
  filters?: OrderQueryFilters | null;
  limit?: number | null;
  lineColumns?: OrderedColumn[] | null;
  orderColumns?: OrderedColumn[] | null;
}

interface CachedTask {
  filters?: TaskQueryFilters | null;
  limit?: number | null;
}

export interface CacheState {
  activity: Record<CachedActivityType, EntityTypeOptionValue>;
  orders: CachedOrderList;
  task: CachedTask;
  shipment: {
    filters: ShipmentQueryFilters | null;
    columns: OrderedColumn[] | null;
  };
  expand: Record<CachedExpandType, boolean> & Record<OrderPanelType, boolean> & Record<LinePanelType, boolean>;
  orderDetailPanels: Record<OrderPanelType, number>;
  lineDetailPanels: Record<LinePanelType, number>;
  converter: {
    generalOrderColumns: ConvertedColumn[];
    buyerOrderColumns: ConvertedColumn[];
    supplierOrderColumns: ConvertedColumn[];

    generalLineColumns: ConvertedColumn[];
    buyerLineColumns: ConvertedColumn[];
    confirmedLineColumns: ConvertedColumn[];
    supplierLineColumns: ConvertedColumn[];
  };
}

export const initialState: CacheState = {
  activity: {
    order: {},
    orderLine: {},
    dashboard: {},
    settings: {},
    network: {},
    shipment: {},
  },
  orders: {
    viewMode: 'orders',
    sort: undefined,
    filters: undefined,
    limit: undefined,
  },
  orderDetailPanels: {
    [OrderPanelType.SUMMARY]: 1,
    [OrderPanelType.INVOICE]: 2,
    [OrderPanelType.PROPERTIES]: 3,
    [OrderPanelType.NOTES]: 4,
    [OrderPanelType.DESTINATION]: 5,
    [OrderPanelType.TERMS]: 6,
    [OrderPanelType.CONTACTS]: 7,
    [OrderPanelType.DOCUMENTS]: 8,
  },
  lineDetailPanels: {
    [LinePanelType.LINE_SUMMARY]: 1,
    [LinePanelType.LINE_ITEM]: 2,
    [LinePanelType.LINE_CHARGE_INFO]: 3,
    [LinePanelType.LINE_DELIVERY_SCHEDULE]: 4,
    [LinePanelType.LINE_SHIPPING]: 5,
    [LinePanelType.LINE_DELIVERY_HISTORY]: 6,
    [LinePanelType.LINE_PRICING]: 7,
    [LinePanelType.LINE_NOTES]: 8,
    [LinePanelType.LINE_PROPERTIES]: 9,
    [LinePanelType.LINE_CONTACTS]: 10,
    [LinePanelType.LINE_CERTIFICATION]: 11,
    [LinePanelType.LINE_ITEM_DETAILS]: 12,
    [LinePanelType.LINE_DOCUMENTS]: 13,
  },
  shipment: {
    filters: null,
    columns: null,
  },
  converter: {
    buyerLineColumns: [],
    buyerOrderColumns: [],
    confirmedLineColumns: [],
    generalLineColumns: [],
    generalOrderColumns: [],
    supplierLineColumns: [],
    supplierOrderColumns: [],
  },
  task: {
    filters: undefined,
    limit: undefined,
  },
  expand: {
    // Shared
    search: true,
    companyIds: false,

    // Order / line
    processStatus: false,
    logisticsStatus: false,
    contactIds: false,
    destinations: false,
    type: false,
    customLabels: false,
    prediction: false,

    // Tasks
    contact: false,
    orderType: false,
    module: false,
    category: false,
    assignedTo: true,

    // Other
    invoice: true,
    summary: true,
    notes: true,
    properties: true,
    destination: true,
    terms: true,
    contacts: true,
    documents: true,

    [LinePanelType.LINE_SUMMARY]: true,
    [LinePanelType.LINE_ITEM]: true,
    [LinePanelType.LINE_CERTIFICATION]: true,
    [LinePanelType.LINE_CHARGE_INFO]: true,
    [LinePanelType.LINE_NOTES]: true,
    [LinePanelType.LINE_PROPERTIES]: true,
    [LinePanelType.LINE_PRICING]: true,
    [LinePanelType.LINE_DELIVERY_SCHEDULE]: true,
    [LinePanelType.LINE_SHIPPING]: true,
    [LinePanelType.LINE_DELIVERY_HISTORY]: true,
    [LinePanelType.LINE_ITEM_DETAILS]: true,
    [LinePanelType.LINE_CONTACTS]: true,
    [LinePanelType.LINE_DOCUMENTS]: true,
  },
};

const cacheReducer = createReducer<
  CacheState,
  TypedAction<CacheActionType | OrderListActionType | UserActionType | CompanyActionType>
>(
  initialState,
  on(getCachedActivityValue, cachingActivityValue, (state, action) => {
    return {
      ...state,
      activity: {
        ...state.activity,
        [action.path]: action.value,
      },
    };
  }),
  on(updateOrderPanels, (state, action) => {
    return {
      ...state,
      orderDetailPanels: {
        ...action.value,
      },
    };
  }),
  on(getCachedOrderPanels, (state, action) => {
    if (action.value) {
      return {
        ...state,
        orderDetailPanels: {
          ...action.value,
        },
      };
    }

    return { ...state };
  }),
  on(updateLinePanels, (state, action) => {
    return {
      ...state,
      lineDetailPanels: {
        ...action.value,
      },
    };
  }),
  on(getCachedLinePanels, (state, action) => {
    if (action.value) {
      return {
        ...state,
        lineDetailPanels: {
          ...action.value,
        },
      };
    }

    return { ...state };
  }),
  on(getCachedExpandValue, cachingExpandValue, (state, action) => {
    return {
      ...state,
      expand: {
        ...state.expand,
        [action.path]: action.value === null ? state.expand[action.path] : action.value,
      },
    };
  }),
  on(
    getCachedConverterColumns,
    (
      state,
      {
        generalOrderColumns,
        buyerOrderColumns,
        supplierOrderColumns,

        generalLineColumns,
        buyerLineColumns,
        confirmedLineColumns,
        supplierLineColumns,
      },
    ) => {
      return {
        ...state,
        converter: {
          generalOrderColumns:
            generalOrderColumns.length === 0 ? state.converter.generalOrderColumns : generalOrderColumns,
          buyerOrderColumns: buyerOrderColumns.length === 0 ? state.converter.buyerOrderColumns : buyerOrderColumns,
          supplierOrderColumns:
            supplierOrderColumns.length === 0 ? state.converter.supplierOrderColumns : supplierOrderColumns,

          generalLineColumns: generalLineColumns.length === 0 ? state.converter.generalLineColumns : generalLineColumns,
          buyerLineColumns: buyerLineColumns.length === 0 ? state.converter.buyerLineColumns : buyerLineColumns,
          confirmedLineColumns:
            confirmedLineColumns.length === 0 ? state.converter.confirmedLineColumns : confirmedLineColumns,
          supplierLineColumns:
            supplierLineColumns.length === 0 ? state.converter.supplierLineColumns : supplierLineColumns,
        },
      };
    },
  ),

  on(orderListReset, state => {
    return {
      ...state,
      orders: {
        ...state.orders,
        filters: undefined,
      },
    };
  }),
  on(orderListUpdateViewMode, getCachedViewMode, (state, { viewMode }) => {
    return { ...state, orders: { ...state.orders, viewMode } };
  }),
  on(orderListUpdateSort, getCachedOrdersSort, (state, { sort }) => {
    return { ...state, orders: { ...state.orders, sort } };
  }),
  on(orderListUpdateFilters, getCachedOrdersFilters, (state, { filters }) => {
    return { ...state, orders: { ...state.orders, filters } };
  }),
  on(cachingOrderListLimit, getCachedOrderListLimit, (state, { limit }) => {
    return { ...state, orders: { ...state.orders, limit } };
  }),
  on(getCachedOrderListColumns, (state, { lineColumns, orderColumns }) => {
    return {
      ...state,
      orders: { ...state.orders, lineColumns, orderColumns },
    };
  }),
  on(orderListUpdateOrderColumns, (state, { orderColumns }) => {
    return {
      ...state,
      orders: { ...state.orders, orderColumns },
    };
  }),
  on(getCachedShipmentColumns, shipmentUpdateColumns, (state, { columns }) => {
    return {
      ...state,
      shipment: { ...state.shipment, columns },
    };
  }),
  on(orderListUpdateLineColumns, (state, { lineColumns }) => {
    return {
      ...state,
      orders: { ...state.orders, lineColumns },
    };
  }),
  on(taskListUpdateFilters, taskListResetFilters, getCachedTaskFilters, (state, { filters }) => {
    return { ...state, task: { filters } };
  }),
  on(cachingTaskLimit, getCachedTaskLimit, (state, { limit }) => {
    return {
      ...state,
      task: {
        filters: state.task.filters,
        limit,
      },
    };
  }),
  on(shipmentUpdateFilters, getCachedShipmentQuery, (state, { filters }) => {
    return { ...state, shipment: { ...state.shipment, filters } };
  }),
  // if entity does not exist remove from filter
  on(companyFetchFailure, (state, { id: deletedId }) => {
    const updatedState: {
      orders?: CachedOrderList;
      task?: CachedTask;
    } = {};

    if (state.orders?.filters?.companyIds) {
      updatedState.orders = {
        ...state.orders,
        filters: {
          ...state.orders.filters,
          companyIds: [
            ...state.orders.filters.companyIds.filter(id => {
              return id !== deletedId;
            }),
          ],
        },
      };
    }

    if (state.task.filters?.relatedCompanyIds) {
      updatedState.task = {
        ...state.task,
        filters: {
          ...state.task.filters,
          relatedCompanyIds: [
            ...state.task.filters.relatedCompanyIds.filter(id => {
              return id !== deletedId;
            }),
          ],
        },
      };
    }

    return { ...state, ...updatedState };
  }),
  on(userFetchFailure, (state, { id: deletedId }) => {
    const updatedState: {
      orders?: CachedOrderList;
      task?: CachedTask;
    } = {};

    if (state.task.filters?.contactUserIds) {
      updatedState.task = {
        ...state.task,
        filters: {
          ...state.task.filters,
          contactUserIds: [
            ...state.task.filters.contactUserIds.filter(id => {
              return id !== deletedId;
            }),
          ],
        },
      };
    }

    if (state.orders?.filters?.contactIds) {
      updatedState.orders = {
        ...state.orders,
        filters: {
          ...state.orders.filters,
          contactIds: [
            ...state.orders.filters.contactIds.filter(id => {
              return id !== deletedId;
            }),
          ],
        },
      };
    }

    return { ...state, ...updatedState };
  }),
  on(validatedCachedDestinations, (state, { destinations }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        filters: {
          ...state.orders.filters!,
          destinations,
        },
      },
    };
  }),
);

export function reducer(state = initialState, action: TypedAction<CacheActionType>): CacheState {
  return cacheReducer(state, action);
}
