import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerLine, OrderLine, OrderLineRequest, DeliveryRequest } from '@app/order/models';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { isChargeLinesChanged } from '@app/order/shared/components/charge-lines-difference/charge-lines-difference.component';
import { priceUnitOfMeasureIsoEquals } from '@app/order/util/helper';
import { SortType } from '../line-reopen/line-reopen.component';

@Component({
  selector: 'tc-line-proposal',
  templateUrl: './line-proposal.component.html',
  styleUrls: ['./line-proposal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineProposalComponent {
  @Input()
  orderLine!: OrderLine;

  public get current(): BuyerLine {
    return this.orderLine.buyerLine;
  }

  public get currentDeliverySchedule(): DeliveryRequest[] {
    return (this.orderLine.deliverySchedule && this.orderLine.deliverySchedule.length > 0)
      ? this.orderLine.deliverySchedule
      : (this.orderLine.buyerLine.deliverySchedule && this.orderLine.buyerLine.deliverySchedule.length > 0)
        ? this.orderLine.buyerLine.deliverySchedule
        : [];
  }

  public get proposalDeliverySchedule(): DeliveryRequest[] {
    return (this.orderLine.deliveryScheduleIncludingRequests && this.orderLine.deliveryScheduleIncludingRequests.length > 0)
      ? this.orderLine.deliveryScheduleIncludingRequests
      : (this.proposal.deliverySchedule && this.proposal.deliverySchedule.length > 0)
        ? this.proposal.deliverySchedule
        : [];
    }

  public get proposal(): OrderLineRequest {
    if (this.orderLine && this.orderLine.supplierLine.requests && this.orderLine.supplierLine.requests.reopenRequest) {
      return this.orderLine.supplierLine.requests.reopenRequest;
    }

    return this.orderLine.supplierLine.requests.proposal!;
  }

  public get isSingleDelivery(): boolean {
    return this.proposalDeliverySchedule.length === 1 && this.currentDeliverySchedule.length === 1;
  }

  public get singleDeliveryDateChanged(): boolean {
    return this.isDeliveryDateChanged(0);
  }

  public get singleDeliveryQuantityChanged(): boolean {
    return this.isDeliveryQuantityChanged(0);
  }

  public get deliveryScheduleChanged(): boolean {
    return (
      !!this.proposalDeliverySchedule &&
      (this.proposalDeliverySchedule.length !== this.currentDeliverySchedule.length ||
        this.proposalDeliverySchedule.some((item, index) => {
          return this.isDeliveryQuantityChanged(index) || this.isDeliveryDateChanged(index);
        }))
    );
  }

  public get grossPriceChanged(): boolean {
    return (
      this.priceUnitChanged ||
      this.proposal.prices.grossPrice?.priceInTransactionCurrency.currencyIso !==
        this.current.prices.grossPrice?.priceInTransactionCurrency.currencyIso ||
      this.proposal.prices.priceUnitQuantity !== this.current.prices.priceUnitQuantity ||
      (!!this.current.prices.grossPrice &&
        priceDifference(this.current.prices.grossPrice, this.proposal.prices.grossPrice) !== 0)
    );
  }

  /* eslint-disable no-prototype-builtins */
  public get discountPercentageChanged(): boolean {
    return (
      this.proposal.prices.hasOwnProperty('discountPercentage') &&
      this.current.prices.hasOwnProperty('discountPercentage') &&
      this.proposal.prices.discountPercentage !== this.current.prices.discountPercentage
    );
  }

  /* eslint-enable no-prototype-builtins */

  public get netPriceChanged(): boolean {
    return this.netPriceUnitChanged || this.pricePerItemChanged;
  }

  public get chargeLineChanged(): boolean {
    return (
      this.current?.chargeLines &&
      this.proposal?.chargeLines &&
      isChargeLinesChanged(this.current.chargeLines, this.proposal.chargeLines)
    );
  }

  findMatchingRequest(currentLine: DeliveryRequest, index: number | undefined = undefined): DeliveryRequest | undefined {
    if (this.sortType === SortType.Index && index !== undefined) {
      return this.proposalDeliverySchedule[index];
    }

    return this.proposalDeliverySchedule.find(line => line.position === currentLine.position);
  }

  findMatchingCurrentLine(reopenLine: DeliveryRequest, index: number | undefined = undefined): DeliveryRequest | undefined {
    if (this.sortType === SortType.Index && index !== undefined) {
      return this.currentDeliverySchedule[index];
    }

    return this.currentDeliverySchedule.find(line => line.position === reopenLine.position);
  }

  isUnmatchedReopenLine(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    return !this.findMatchingCurrentLine(reopenLine, index) || !reopenLine.position;
  }

  isUnmatchedCurrentLine(currentLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    return !this.findMatchingRequest(currentLine, index);
  }

  hasDateDiff(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    const currentLine = this.findMatchingCurrentLine(reopenLine, index);
    if (!currentLine) return true;
    const diff = dateDifference(currentLine.date, reopenLine.date);

    return diff !== 0;
  }

  hasQuantityDiff(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    const currentLine = this.findMatchingCurrentLine(reopenLine, index);
    if (!currentLine) return true;
    const diff = quantityDifference(currentLine.quantity, reopenLine.quantity);

    return diff !== undefined && diff !== 0;
  }

  public checkPositionsExist(): boolean {
    return this.currentDeliverySchedule.every(line => line?.position !== undefined);
  }

  public isLessPositionsInCurrentSchedule(): boolean {
    const uniquePositions = new Set(
      this.currentDeliverySchedule
        .map(line => line?.position)
        .filter(position => position !== undefined)
    );

    return uniquePositions.size < this.currentDeliverySchedule.length;
  }

  public checkReopenPositionsExist(): boolean {
    return this.proposalDeliverySchedule.every(line => line?.position !== undefined);
  }

  public isLessPositionsInReopenSchedule(): boolean {
    const uniquePositions = new Set(
      this.proposalDeliverySchedule
        .map(line => line?.position)
        .filter(position => position !== undefined)
    );

    return uniquePositions.size < this.proposalDeliverySchedule.length;
  }

  public hasNoPositionsInReopenSchedule(): boolean {
    return this.proposalDeliverySchedule.every(line => line?.position === undefined);
  }

  public hasNoPositionsInCurrentSchedule(): boolean {
    return this.currentDeliverySchedule.every(line => line?.position === undefined);
  }

  public get sortType(): SortType {
    if (this.hasNoPositionsInCurrentSchedule()) {
      return SortType.Index;
    }

    if (this.hasNoPositionsInReopenSchedule()) {
      return SortType.Index;
    }

    return SortType.Position;
  }

  private get priceUnitChanged(): boolean {
    return !priceUnitOfMeasureIsoEquals(
      this.proposal.prices.priceUnitOfMeasureIso,
      this.current.prices.priceUnitOfMeasureIso,
    );
  }

  private get netPriceUnitChanged(): boolean {
    return (
      this.priceUnitChanged ||
      this.proposal.prices.netPrice?.priceInTransactionCurrency.currencyIso !==
        this.current.prices.netPrice?.priceInTransactionCurrency.currencyIso
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.proposal.prices.netPrice?.priceInTransactionCurrency &&
      !!this.current.prices.netPrice?.priceInTransactionCurrency &&
      this.proposal.prices.netPrice.priceInTransactionCurrency.value / this.proposal.prices.priceUnitQuantity !==
        this.current.prices.netPrice.priceInTransactionCurrency.value / this.current.prices.priceUnitQuantity
    );
  }

  private isDeliveryDateChanged(index: number): boolean {
    return dateDifference(this.current.deliverySchedule[index].date, this.proposal?.deliverySchedule[index].date) !== 0;
  }

  private isDeliveryQuantityChanged(index: number): boolean {
    return (
      quantityDifference(
        this.current.deliverySchedule[index].quantity,
        this.proposal?.deliverySchedule[index].quantity,
      ) !== 0
    );
  }
}
