import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';

export function showColumnsByDef(columns: OrderedColumn[], columnDefs: string[]): OrderedColumn[] {
  return columns.map(column => {
    return {
      ...column,
      show: columnDefs.includes(column.columnDef) ? true : column.show,
    };
  });
}
