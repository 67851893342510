import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { UUID } from '@app/shared/models';
import { Shipment, ShipmentQuery, ShipmentQueryResponse } from '@app/shipment/models';

import {
  ShipmentActionType,
  shipmentAddMany,
  shipmentInitiatedColumns,
  shipmentQuery,
  shipmentQuerySuccess,
  shipmentRefresh,
  shipmentSetShowing,
  shipmentUpdateColumns,
  shipmentUpsert,
  shipmentWebSocketUpsert,
} from './shipment.actions';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';
import { shipmentDefaultTableColumns } from './constants/shipment-default-table-columns';

export interface ShipmentState extends EntityState<Shipment> {
  readonly columns: OrderedColumn[];
  readonly ids: UUID[];
  readonly total: number;
  readonly showDocument: boolean;
}

export const shipmentAdapter = createEntityAdapter<Shipment>({
  selectId: entity => {
    return entity.id;
  },
});

export const initialState = shipmentAdapter.getInitialState({
  columns: shipmentDefaultTableColumns,
  ids: [],
  total: 0,
  showDocument: false,
});

const shipmentQuerySuccessReducer: OnReducer<
  ShipmentState,
  [
    ActionCreator<
      ShipmentActionType.QUERY_SUCCESS,
      (props: {
        query: ShipmentQuery;
        response: ShipmentQueryResponse;
      }) => { query: ShipmentQuery; response: ShipmentQueryResponse }
    >,
  ]
> = (state, { response: { data, total }, query: { offset, limit, filters } }) => {
  return { ...state, filters, total };
};

const shipmentReducer = createReducer<ShipmentState, TypedAction<ShipmentActionType>>(
  initialState,
  on(shipmentQuery, (state, { query: { limit } }) => {
    return {
      ...state,
      ids: new Array(limit).fill(null),
    };
  }),
  on(shipmentQuerySuccess, shipmentQuerySuccessReducer),
  on(shipmentUpdateColumns, shipmentInitiatedColumns, (state, { columns }) => {
    return { ...state, columns };
  }),
  on(shipmentSetShowing, (state, { value }) => {
    return { ...state, showDocument: value };
  }),
  on(shipmentAddMany, (state, { entities }) => {
    return shipmentAdapter.setAll(entities, state);
  }),
  on(shipmentUpsert, shipmentWebSocketUpsert, (state, { entity }) => {
    return shipmentAdapter.upsertOne(entity, state);
  }),
  on(shipmentRefresh, state => {
    return state;
  }),
);

export function reducer(state = initialState, action: TypedAction<ShipmentActionType>): ShipmentState {
  return shipmentReducer(state, action);
}
