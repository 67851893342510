import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerLine, ConfirmedLine, DeliveryRequest, OrderLine, OrderLineRequest } from '@app/order/models';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { isChargeLinesChanged } from '@app/order/shared/components/charge-lines-difference/charge-lines-difference.component';
import { hasOpenSupplierReopenRequest, priceUnitOfMeasureIsoEquals } from '@app/order/util/helper';

export enum SortType {
  Index = 'index',
  Position = 'position',
}

@Component({
  selector: 'tc-line-reopen',
  templateUrl: './line-reopen.component.html',
  styleUrls: ['./line-reopen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineReopenComponent {
  @Input()
  orderLine!: OrderLine;

  public get reopenDeliverySchedule(): DeliveryRequest[] {
    return (this.orderLine && this.orderLine.deliveryScheduleIncludingRequests && this.orderLine.deliveryScheduleIncludingRequests.length > 0)
      ? this.orderLine.deliveryScheduleIncludingRequests
      : (this.reopen && this.reopen.deliverySchedule && this.reopen.deliverySchedule.length > 0)
        ? this.reopen.deliverySchedule
        : [];
  }

  get currentDeliverySchedule(): DeliveryRequest[] {
    return (this.orderLine && this.orderLine.deliverySchedule && this.orderLine.deliverySchedule.length > 0)
      ? this.orderLine.deliverySchedule
      : (this.orderLine && this.orderLine.confirmedLine && this.orderLine.confirmedLine.deliverySchedule && this.orderLine.confirmedLine.deliverySchedule.length > 0)
        ? this.orderLine.confirmedLine.deliverySchedule
        : [];
  }

  public get current(): ConfirmedLine | undefined {
    return this.orderLine?.confirmedLine;
  }

  public get buyer(): BuyerLine {
    return this.orderLine.buyerLine;
  }

  public get reopen(): OrderLineRequest | undefined {
    if (hasOpenSupplierReopenRequest(this.orderLine)) {
      return this.orderLine.supplierLine.requests.reopenRequest;
    }

    if (this.orderLine?.buyerLine?.requests?.reopenRequest?.status === 'Open') {
      return this.orderLine.buyerLine.requests.reopenRequest;
    }
  }

  public get isSingleDelivery(): boolean {
    return this.reopenDeliverySchedule.length === 1 && this.currentDeliverySchedule?.length === 1;
  }

  public get singleDeliveryDateChanged(): boolean {
    if (!this.isSingleDelivery) return false;
    const reopenLine = this.reopenDeliverySchedule[0];

    return this.hasDateDiff(reopenLine);
  }

  public get singleDeliveryQuantityChanged(): boolean {
    if (!this.isSingleDelivery) return false;
    const reopenLine = this.reopenDeliverySchedule[0];

    return this.hasQuantityDiff(reopenLine);
  }

  public get deliveryScheduleChanged(): boolean {
    if (!this.reopenDeliverySchedule?.length || !this.currentDeliverySchedule?.length) {
      return false;
    }

    // Get all unique positions from both schedules
    const currentPositions = new Set(this.currentDeliverySchedule.map(line => line.position));
    const reopenPositions = new Set(this.reopenDeliverySchedule.map(line => line.position));

    // Check for unmatched positions in either direction
    const hasUnmatchedPositions = Array.from(currentPositions).some(pos => !reopenPositions.has(pos)) ||
                                 Array.from(reopenPositions).some(pos => !currentPositions.has(pos));

    if (hasUnmatchedPositions) {
      return true;
    }

    // Check for differences in matching positions
    return Array.from(currentPositions).some(position => {
      const currentLine = this.currentDeliverySchedule.find(line => line.position === position);
      const reopenLine = this.reopenDeliverySchedule.find(line => line.position === position);

      if (!currentLine || !reopenLine) {
        return true;
      }

      const qtyDiff = quantityDifference(currentLine.quantity, reopenLine.quantity);
      const dateDiff = dateDifference(currentLine.date, reopenLine.date);

      return (qtyDiff !== undefined && qtyDiff !== 0) || (dateDiff !== undefined && dateDiff !== 0);
    });
  }

  /* eslint-enable no-prototype-builtins */

  public get grossPriceChanged(): boolean {
    return (
      this.hasGrossPriceTransactionCurrencyChanged() ||
      this.priceUnitChanged ||
      (this.pricePerItemChanged &&
        (this.reopen?.prices.priceUnitQuantity !== this.current?.prices.priceUnitQuantity ||
          (!!this.current?.prices.grossPrice &&
            priceDifference(this.current?.prices.grossPrice, this.reopen?.prices.grossPrice) !== 0)))
    );
  }

  /* eslint-disable no-prototype-builtins */
  public get discountPercentageChanged(): boolean | undefined {
    return (
      this.reopen?.prices.hasOwnProperty('discountPercentage') &&
      this.current?.prices.hasOwnProperty('discountPercentage') &&
      this.reopen?.prices.discountPercentage !== this.current?.prices.discountPercentage
    );
  }

  /* eslint-enable no-prototype-builtins */

  public get netPriceChanged(): boolean {
    return this.hasNetPriceTransactionCurrencyAndUnitChanged() || this.pricePerItemChanged;
  }

  public get chargeChanged(): boolean {
    return (
      !!this.current?.chargeLines &&
      !!this.reopen?.chargeLines &&
      isChargeLinesChanged(this.current.chargeLines, this.reopen.chargeLines)
    );
  }

  private hasGrossPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.reopen?.prices.grossPrice?.priceInTransactionCurrency.currencyIso &&
      !!this.current?.prices.grossPrice?.priceInTransactionCurrency.currencyIso &&
      this.reopen.prices.grossPrice.priceInTransactionCurrency.currencyIso !==
        this.current.prices.grossPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private hasNetPriceTransactionCurrencyAndUnitChanged(): boolean {
    return (
      !!this.reopen?.prices.netPrice?.priceInTransactionCurrency.currencyIso &&
      !!this.current?.prices.netPrice?.priceInTransactionCurrency.currencyIso &&
      (this.reopen.prices.netPrice.priceInTransactionCurrency.currencyIso !==
        this.current.prices.netPrice.priceInTransactionCurrency.currencyIso ||
        this.priceUnitChanged)
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.reopen?.prices.netPrice?.priceInTransactionCurrency.value &&
      !!this.current?.prices.netPrice?.priceInTransactionCurrency.value &&
      this.reopen.prices.netPrice.priceInTransactionCurrency.value / this.reopen.prices.priceUnitQuantity !==
        this.current.prices.netPrice.priceInTransactionCurrency.value / this.current.prices.priceUnitQuantity
    );
  }

  private get priceUnitChanged(): boolean {
    return !priceUnitOfMeasureIsoEquals(
      this.reopen?.prices.priceUnitOfMeasureIso,
      this.current?.prices.priceUnitOfMeasureIso,
    );
  }

  findMatchingReopenLine(currentLine: DeliveryRequest, index: number | undefined = undefined): DeliveryRequest | undefined {
    if (this.sortType === SortType.Index && index !== undefined) {
      return this.reopenDeliverySchedule[index];
    }

    return this.reopenDeliverySchedule.find(line => line.position === currentLine.position);
  }

  findMatchingCurrentLine(reopenLine: DeliveryRequest, index: number | undefined = undefined): DeliveryRequest | undefined {
    if (this.sortType === SortType.Index && index !== undefined) {
      return this.currentDeliverySchedule[index];
    }

    return this.currentDeliverySchedule.find(line => line.position === reopenLine.position);
  }

  isUnmatchedReopenLine(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    return !this.findMatchingCurrentLine(reopenLine, index) || !reopenLine.position;
  }

  isUnmatchedCurrentLine(currentLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    return !this.findMatchingReopenLine(currentLine, index);
  }

  hasDateDiff(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    const currentLine = this.findMatchingCurrentLine(reopenLine, index);
    if (!currentLine) return true;

    const diff = dateDifference(currentLine.date, reopenLine.date);

    return diff !== 0;
  }

  hasQuantityDiff(reopenLine: DeliveryRequest, index: number | undefined = undefined): boolean {
    const currentLine = this.findMatchingCurrentLine(reopenLine, index);
    if (!currentLine) return true;
    const diff = quantityDifference(currentLine.quantity, reopenLine.quantity);

    return diff !== undefined && diff !== 0;
  }

  public checkPositionsExist(): boolean {
    return this.currentDeliverySchedule.every(line => line?.position !== undefined);
  }

  public isLessPositionsInCurrentSchedule(): boolean {
    const uniquePositions = new Set(
      this.currentDeliverySchedule
        .map(line => line?.position)
        .filter(position => position !== undefined)
    );

    return uniquePositions.size < this.currentDeliverySchedule.length;
  }

  public checkReopenPositionsExist(): boolean {
    return this.reopenDeliverySchedule.every(line => line?.position !== undefined);
  }

  public isLessPositionsInReopenSchedule(): boolean {
    const uniquePositions = new Set(
      this.reopenDeliverySchedule
        .map(line => line?.position)
        .filter(position => position !== undefined)
    );

    return uniquePositions.size < this.reopenDeliverySchedule.length;
  }

  public hasNoPositionsInReopenSchedule(): boolean {
    return this.reopenDeliverySchedule.every(line => line?.position === undefined);
  }

  public hasNoPositionsInCurrentSchedule(): boolean {
    return this.currentDeliverySchedule.every(line => line?.position === undefined);
  }

  public get sortType(): SortType {
    if (this.hasNoPositionsInCurrentSchedule()) {
      return SortType.Index;
    }

    if (this.hasNoPositionsInReopenSchedule()) {
      return SortType.Index;
    }

    return SortType.Position;
  }
}
