import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { ShipmentTableColumnsType } from '@app/shipment/models/enums/shipment-table-columns-type.enum';

// todo: i18n
export const shipmentDefaultTableColumns: OrderedColumn[] = [
  {
    title: 'Buyer',
    columnDef: ShipmentTableColumnsType.BUYER,
    show: false,
  },
  {
    title: 'Supplier',
    columnDef: ShipmentTableColumnsType.SUPPLIER,
    show: false,
  },
  {
    title: 'Shipment number',
    columnDef: ShipmentTableColumnsType.SHIPMENT_NUMBER,
    show: true,
  },
  {
    title: 'References',
    columnDef: ShipmentTableColumnsType.REFERENCES,
    show: true,
  },
  {
    title: 'Load carriers',
    columnDef: ShipmentTableColumnsType.LOAD_CARRIES,
    show: true,
  },
  {
    title: 'Departure',
    columnDef: ShipmentTableColumnsType.DEPARTURE_LOCATION,
    show: true,
  },
  {
    title: 'Port Of loading',
    columnDef: ShipmentTableColumnsType.PORT_OF_LOADING,
    show: true,
  },
  {
    title: 'Mode of transport',
    columnDef: ShipmentTableColumnsType.MODE_OF_TRANSPORT,
    show: true,
  },
  {
    title: 'Port of destination',
    columnDef: ShipmentTableColumnsType.PORT_OF_DETINSTINATION,
    show: true,
  },
  {
    title: 'Final Destination',
    columnDef: ShipmentTableColumnsType.FINAL_DESTINATION,
    show: true,
  },
  {
    title: 'Process status',
    columnDef: ShipmentTableColumnsType.PROCESS_STATUS,
    show: true,
  },
];
