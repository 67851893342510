import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, filter, map, mapTo, mergeMapTo, withLatestFrom } from 'rxjs/operators';
import { NotificationType } from '@app/core/state/core.model';
import { notificationSend, trackEvent } from '@app/core/state/core.actions';
import {
  shipmentAddMany,
  shipmentDetailApprove,
  shipmentDetailApproveFailure,
  shipmentDetailApproveSuccess,
  shipmentDetailAttach,
  shipmentDetailAttachFailure,
  shipmentDetailReject,
  shipmentDetailRejectFailure,
  shipmentDetailRejectSuccess,
  shipmentDetailResend,
  shipmentDetailResendFailure,
  shipmentDetailResendSuccess,
  shipmentDetailStartReject,
  shipmentFetch,
  shipmentFetchFailure,
  shipmentInitColumns,
  shipmentInitiatedColumns,
  shipmentListen,
  shipmentQuery,
  shipmentQueryFailure,
  shipmentQuerySuccess,
  shipmentRefresh,
  shipmentStartCreate,
  shipmentUpdateFilters,
  shipmentUpsert,
  shipmentWebSocketConnected,
  shipmentWebSocketUpsert,
} from './shipment.actions';
import { ShipmentState } from '@app/shipment/core/state/shipment.reducer';
import { selectRoutedShipmentId, selectShipmentIds, selectShipmentsQuery } from './shipment.selectors';
import { ShipmentSearchService } from '@app/shipment/core/services/shipment-search.service';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { makeErrorMessage } from '@app/util/helpers';
import { ShipmentService } from '@app/shipment/core/services/shipment.service';
import { resetPagingOffset } from '@app/task/routes/task-list/state/task-list.effects';
import { mapShipmentFiltersToQueryParameters } from '@app/shipment/core/state/util';
import { Router } from '@angular/router';
import { CachedKey } from '@app/cache/models';
import { saveToStorage } from '@app/cache/state/cache.actions';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';
import { MatDialog } from '@angular/material/dialog';
import { ShipmentRejectDialogComponent } from '@app/shipment/private/components/shipment-reject-dialog/shipment-reject-dialog.component';
import { showColumnsByDef } from '@app/order/routes/order-list/containers/helpers/show-columns-by-def.helper';
import { selectCachedShipmentListColumns } from '@app/cache/state/cache.selectors';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';
import { shipmentDefaultTableColumns } from './constants/shipment-default-table-columns';
import { ShipmentTableColumnsType } from '@app/shipment/models/enums/shipment-table-columns-type.enum';

@Injectable()
export class ShipmentEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentFetch),
      concatMap(({ id, silent }) => {
        return of(id).pipe(
          filterById(this.store$.pipe(select(selectShipmentIds))),
          fetchById(shipmentId => {
            return this.shipmentSearchService.getById$(shipmentId).pipe(
              map(entity => {
                return shipmentUpsert({ entity });
              }),
              catchError(({ error }: HttpErrorResponse) => {
                return of(
                  shipmentFetchFailure({
                    id: shipmentId,
                    error: error.message || error,
                    silent: Boolean(silent),
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  });

  listen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentListen),
      map(({ companyId }) => {
        return companyId;
      }),
      concatMap(companyId => {
        return this.shipmentService.websocket$(companyId, shipmentWebSocketConnected).pipe(
          map(
            entity => {
              return shipmentWebSocketUpsert({ entity });
            },
            catchError(() => {
              return EMPTY;
            }),
          ),
        );
      }),
    );
  });

  query$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentQuery),
      map(({ query }) => {
        return query;
      }),
      concatMap(query => {
        return this.shipmentSearchService.query$(query).pipe(
          map(response => {
            return shipmentQuerySuccess({ query, response });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(shipmentQueryFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  querySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentQuerySuccess),
      map(({ response: { data } }) => {
        return data;
      }),
      map(entities => {
        return shipmentAddMany({ entities });
      }),
    );
  });

  queryFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentQueryFailure),
      mapTo(
        notificationSend({
          message: 'Failed to query the shipments. Please try again', // todo: i18n
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentRefresh),
      withLatestFrom(this.store$.pipe(select(selectShipmentsQuery))),
      filter(([_, query]) => {
        return !!query;
      }),
      map(([_, query]) => {
        return shipmentQuery({ query });
      }),
    );
  });

  updateFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(shipmentUpdateFilters),
        map(({ filters }) => {
          return filters;
        }),
        map(mapShipmentFiltersToQueryParameters),
        map(resetPagingOffset),
        map(async queryParams => {
          return this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
        }),
      );
    },
    { dispatch: false },
  );

  saveFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentUpdateFilters),
      map(({ filters }) => {
        return saveToStorage({ value: filters, key: CachedKey.SHIPMENT_FILTERS });
      }),
    );
  });

  route$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.store$.pipe(select(selectRoutedShipmentId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([action, id]) => {
        return shipmentFetch({ id: id!, silent: true });
      }),
    );
  });

  // shipment detail effects move to separate file after creating more effects
  resend$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailResend),
      concatMap(payload => {
        return this.shipmentService.resendShipment$(payload).pipe(
          mapTo(shipmentDetailResendSuccess()),
          catchError(({ error, status }: HttpErrorResponse) => {
            return of(shipmentDetailResendFailure({ status, error }));
          }),
        );
      }),
    );
  });

  resendSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailResendSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.ship.detail.resent.success:Shipment resend`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  resendFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailResendFailure),
      map(makeErrorMessage($localize`:@@ts.order.detail.resent.failed:Your resend failed:`)),
      map(message => {
        return notificationSend({ message, notificationType: NotificationType.ERROR, duration: 10000 });
      }),
    );
  });

  attach$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailAttach),
      concatMap(payload => {
        return this.shipmentService.attachShipmentDocument$(payload).pipe(
          mapTo(trackEvent({ eventName: MixpanelButtonClickEvents.SHIPMENT_ATTACH_DOCUMENT })),
          catchError(({ error, status }: HttpErrorResponse) => {
            return of(shipmentDetailAttachFailure({ error, status }));
          }),
        );
      }),
    );
  });

  attachFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailAttachFailure),
      map(makeErrorMessage($localize`:@@ts.ship.detail.attach.failed:Your attach failed:`)),
      map(message => {
        return notificationSend({ message, notificationType: NotificationType.ERROR, duration: 10000 });
      }),
    );
  });

  approve$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailApprove),
      concatMap(payload => {
        return this.shipmentService.approveShipment$({ shipmentId: payload.id }).pipe(
          mapTo(shipmentDetailApproveSuccess()),
          catchError(({ error, status }: HttpErrorResponse) => {
            return of(shipmentDetailApproveFailure({ status, error }));
          }),
        );
      }),
    );
  });

  approveSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailApproveSuccess),
      mergeMapTo([
        trackEvent({ eventName: MixpanelButtonClickEvents.SHIPMENT_APPROVE }),
        notificationSend({
          message: $localize`:@@ts.shipment.detail.approve.success:Shipment approved`,
          notificationType: NotificationType.SUCCESS,
        }),
      ]),
    );
  });

  approveFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailApproveFailure),
      map(makeErrorMessage($localize`:@@ts.shipment.detail.approve.failed:Your approve failed:`)),
      map(message => {
        return notificationSend({ message, notificationType: NotificationType.ERROR, duration: 10000 });
      }),
    );
  });

  startReject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailStartReject),
      map(({ id }) => {
        return { id };
      }),
      concatMap(payload => {
        return this.dialog
          .open(ShipmentRejectDialogComponent, {
            data: {
              value: payload,
            },
          })
          .beforeClosed()
          .pipe(
            filter(value => {
              return !!value;
            }),
            map(shipmentDetailReject),
          );
      }),
    );
  });

  reject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailReject),
      concatMap(payload => {
        return this.shipmentService.rejectShipment$(payload).pipe(
          mapTo(shipmentDetailRejectSuccess()),
          catchError(({ error, status }: HttpErrorResponse) => {
            return of(shipmentDetailRejectFailure({ status, error }));
          }),
        );
      }),
    );
  });

  rejectSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailRejectSuccess),
      mergeMapTo([
        trackEvent({ eventName: MixpanelButtonClickEvents.SHIPMENT_REJECT }),
        notificationSend({
          message: $localize`:@@ts.shipment.detail.reject.success:Shipment lines rejected`,
          notificationType: NotificationType.SUCCESS,
        }),
      ]),
    );
  });

  rejectFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentDetailRejectFailure),
      map(makeErrorMessage($localize`:@@ts.order.detail.reject.failed:Your rejection failed:`)),
      map(message => {
        return notificationSend({ message, notificationType: NotificationType.ERROR, duration: 10000 });
      }),
    );
  });

  startCreate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(shipmentStartCreate),
        map(async () => {
          return this.router.navigate(['/shipments/create']);
        }),
      );
    },
    { dispatch: false },
  );


  initColumns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentInitColumns),
      withLatestFrom(
        this.store$.select(selectCachedShipmentListColumns),
        this.store$.select(selectCompanyOrderRoleFromIdentity),
      ),
      map(([_, { columns }, role]) => {
        const orderColumnDefs = role === 'buyer' ? [ShipmentTableColumnsType.SUPPLIER] : [ShipmentTableColumnsType.BUYER];

        const showedOrderColumns = showColumnsByDef(shipmentDefaultTableColumns, orderColumnDefs);

        const updatedOrderColumns =
          !columns || columns.length < showedOrderColumns.length ? showedOrderColumns : columns;

        return {
          columns: updatedOrderColumns,
        };
      }),
      map(shipmentInitiatedColumns),
    )
  });

  constructor(
    private actions$: Actions,
    private store$: Store<ShipmentState>,
    private router: Router,
    private dialog: MatDialog,
    private shipmentSearchService: ShipmentSearchService,
    private shipmentService: ShipmentService,
  ) {}
}
